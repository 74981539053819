.lens-check,
.lens-summary {
  display: flex;
  align-items: center;
  .icon-lens {
    align-self: flex-start;
    width: 15px;
    height: 15px;
    overflow: inherit;
  }

  .item-label {
    margin-bottom: -3px;
    font-size: 12px;
    color: $gray-0;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }
  .item-sub {
    overflow-wrap: anywhere;
    margin-bottom: -3px;

    .lens-filter-label,
    .lens-filter-score {
      color: $gray-0;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
    }

    .lens-filter-score {
      white-space: nowrap;
    }
  }
  .spacer {
    flex: 1;
  }

  &.lens-check {
    padding-bottom: 10px;
    border-bottom: 1px solid $black10;
    margin-bottom: 10px;
  }

  .risk-points {
    font-size: 1.3rem;
    font-weight: 500;
    padding-right: 5px;
    margin-left: 0px;
    line-height: 21px;
  }
  .has-risk {
    margin-left: 0px;
  }
  &.pass .icon-result path {
    fill: rgba($black20, 1);
  }

  .lens-info {
    width: 100%;
    .item-label,
    .item-sub {
      margin-bottom: 5px;
    }

    margin-left: 20px;
    .lens-filter-check {
      display: flex;

      .lens-filter-score {
        margin-left: auto;
      }
    }
  }
}
