/*
TimelineView.jsx Styles

TODO: is there some better way to encapsulate/colocate styles with the component?
*/

.timeline-view {
  position: relative;

  .timeline-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0px, 40px);
  }
  .timeline-actions > button {
    margin-left: 5px;
  }

  .timeline-filter {
    @extend .papershadow;
    width: 175px;
    padding: 10px 0 10px 10px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: 1px solid $black20;
    border-radius: 2px;
    background: $white;
    z-index: 1; // Needed to ensure vis-timeline objects stay underneath this.

    .timeline-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-right: 10px;

      .spacer {
        flex: 1;
      }

      span {
        margin-left: 5px;
        font-weight: 600;
        font-size: 1.3rem;
      }
      &:not(.expanded) {
        svg {
          transform: rotate(180deg);
        }
      }

      .lnk-reset {
        color: $black40;
        padding: 0;
        line-height: 1;
      }
    }
    .event-types {
      margin-top: 5px;
      .event-type {
        cursor: pointer;
        padding: 2px 0;
        display: flex;
        align-items: center;

        .spacer {
          flex: 1;
        }

        .type-title {
          font-weight: 600;
          font-size: 12px;
        }

        &.empty .type-title {
          cursor: not-allowed;
          color: $black40;
        }
      }
    }
  }

  .vis-item {
    max-width: 240px;
    box-shadow: 0px 1px 2px 0px #8c98a54d, 0px 4px 4px 0px #8c98a50d;

    &.vis-selected {
      z-index: 3; // Needed to make our Overlay look correct
    }

    .vis-item-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      padding: 10px;

      text-align: left !important;
      white-space: wrap;

      font-size: 10px;
      line-height: 10px;

      .tl-item-info-row {
        display: flex;
        flex-direction: row;
        gap: 4px;
        font-weight: 600;
        color: $gray-0;
      }
      .tl-item-content {
        font-size: 12px;
        line-height: 15px;
      }
    }

    // Style timeline events according to their types
    &.GENERAL {
      @include timelineEvent(#e9f4fe, #2557d1);
    }
    &.MEDICAL {
      @include timelineEvent(#ebfefb, #2e6c74);
    }
    &.INCIDENT {
      @include timelineEvent(#fdffe9, #48651a);
    }
    &.DEPOSITION {
      @include timelineEvent(#fffbe8, #7f5019);
    }
    &.FILING {
      @include timelineEvent(#fdf1f6, #b4317d);
    }
    &.DEATH {
      @include timelineEvent(#fdf2f1, #be2e2d);
    }
  }

  // Hide current date from view
  // https://visjs.github.io/vis-timeline/docs/timeline/#Styles
  .vis-today,
  .vis-tomorrow,
  .vis-yesterday,
  .vis-current-week,
  .vis-current-month,
  .vis-current-year,
  .vis-current-time {
    display: none;
  }
}

// TODO: Can possibly do away with this when the "timeline actions" buttons go away, and just have
// the .timeline-view itself have overflow:hidden.
.timeline-cropper {
  //background-color: rgba(255, 0, 0, 0.5);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  pointer-events: none;
}

.timeline-event-detail-panel {
  position: absolute;
  top: 0px;
  right: -300px;
  width: 300px;
  height: 100%;

  pointer-events: auto;

  transition: transform 0.3s ease-in-out;

  &.visible {
    transform: translateX(-300px);
  }

  z-index: 1; // Needed to ensure vis-timeline objects stay underneath this.

  // TODO: match border and box-shadow with figma
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

  color: $gray-0;
  font-size: 12px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .title-row {
    display: flex;
    flex-direction: row;

    .title {
      flex-grow: 1;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .details-row {
    display: flex;
    flex-direction: row;

    .event-date {
      flex-grow: 1;
    }
    .event-type {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .dmp-swatch {
      margin-right: 5px;
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .summary-editor {
    textarea {
      height: 200px;
    }
  }

  .controls-viewing {
    display: flex;
    flex-direction: row;
    align-items: center;

    .status {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      align-items: center;

      svg {
        margin-right: 2px;
      }
    }
  }

  .controls-editing {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.timeline-selection-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  pointer-events: none; // Allows click-through
  z-index: 2; // Fits right between selected and unselected timeline events

  background: white;

  opacity: 0;
  transition: opacity 0.3s ease-out;
  &.active {
    opacity: 0.8;
  }
  &.inactive {
    transition: none;
  }
}

.timeline-event-blocker-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  z-index: 1; // Needed to ensure vis-timeline objects stay underneath this.
}
