//colors
$shadow-color: rgba(0, 0, 0, 0.2);
$black100: rgba(0, 0, 0, 1);
$black90: rgba(25, 25, 25, 1);
$black80: rgba(51, 51, 51, 1);
$black70: rgba(75, 75, 75, 1);
$black60: rgba(102, 102, 102, 1);
$black40: rgba(153, 153, 153, 1);
$black30: rgba(178, 178, 178, 1);
$black20: rgba(204, 204, 204, 1);
$black10: rgba(229, 229, 229, 1);
$black5: #fafbfc;
$black3: rgba(240, 240, 240, 1);
$black2: rgba(250, 250, 250, 1);
$black1: rgba(252, 252, 252, 1);
$white: rgba(255, 255, 255, 1);
$color-red: rgb(237, 56, 69);

$outlaw: #854ad3;
$primary-dark: #29323c; // $outlaw dark color
$primary-light: #f9f6fd; // $outlaw light color
$outlaw-dark: $primary-dark;
$outlaw-lightgrey: rgb(99, 109, 123);

// Filevine stlyes
$filevine-primary: #009d9a;

// Grays
$gray-dark: #1b232e;
$gray-0: #2b3542;
$gray-1: #5a6573;
$gray-2: #8c98a5;
$gray-3: #d9dee4;
$gray-4: #edeff0;
$gray-5: #fafafa;

// Colors
$color-blue: #67a0f8;
$color-blue-light: lighten($color-blue, 28%);
$color-yellow: #f6d76b;
$color-red: #ee6662;
$color-red-light: lighten(#ee6662, 28%);
$color-orange: #f6b36b;
$color-orange-light: #fef9ef;
$color-green: #2dcb8d;
$color-green-light: lighten($color-green, 28%);
$color-purple: $outlaw;

// Outlaw Deal status (workflow step) colors
$pending: #ffb534;
$done: #46ca8c;
$discuss: #4480f1;
$alert: #ed3845;
$canedit: $black2;
$info: #67a0f8;

// Additional named colors available for custom workflow steps
$color-pink: #f2a5ef;
$color-teal: #49cdd1;
$color-orange: #ee8c62;

//colors of different variable types (Draft)
$entity-party-color: $discuss;
$entity-var-color: $outlaw;
$entity-protected-color: $done;
$entity-connected-color: #00b8bd;
$entity-ref-color: #25b471;
$entity-candidate-color: #f2c40f;
$entity-term-color: #2c3e50;

//media queries
$breakpoint-mobile-small: 325px;
$breakpoint-mobile-large: 425px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 992px;
$breakpoint-desktop-large: 1200px;

//app (and modal) vars
$base-margin: 20px;
$margin-mobile: $base-margin;
$margin-desktop: $base-margin * 3;
$page-medium: $breakpoint-tablet;
$page-large: $breakpoint-desktop;

//deal panels
$panel-header-height: 40px;
$panel-desktop-width: 320px;

//app vars
$top-bar-height: 72px;
$top-bar-height-slim: 40px;
$deal-header-height: 50px;
$deal-header-height-vine: 40px;
$mobile-nav-height: 60px;
$mobile-abc-height: 40px;
$doc-width: 820px;
$activity-width: 200px;
$sidebar-width: 220px;
$bundle-sidebar-width: 250px;
$sig-height: 50px;
$width-for-actions: 200px;
$prompt-width: 30%;
$template-toolbar-height: 40px;
$omnisearch-bar-border-radius: 2px;
$legal-paper-width: 816px; //8.5" x 96 dpi = 816. Also, it's what Google does. :-)
$legal-paper-height: 1056px; //11" x 96 dpi
$legal-paper-margin: 96px; //1" margins

$search-bar-height-mobile: 60px;
$search-bar-height-desktop: 40px;
$search-bar-icon-size: 16px;

//app z-indexes
$layer-abc-compact: 1030;
$layer-mobile-nav: 1020;
$layer-topbar: 1000;
$layer-fixedbars: 999;
$layer-abc: 950;
$layer-activity: 900;
$layer-deal-steps: 800;
$layer-variables: 400;
$layer-color-labels: 300;

//static vars
$static-nav-height: 70px;
$static-button-border-rad: 4px; //static page border radius
$button-border-rad: 3px; //border radius of buttons

//box-shadow for deal/template toolbars
$toolbar-box-shadow: 0px 1px 0px rgba(37, 47, 61, 0.1), 0px 5px 10px rgba(37, 47, 61, 0.1);
