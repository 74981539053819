/*
  React Table
*/

@import '~react-table/react-table.css';
@import '~react-table-hoc-fixed-columns/lib/styles.css';

$rt-border-radius: 3px;

.ReactTable {
  border: 0;

  .-loading {
    &.-active {
      z-index: 4;
    }

    > div {
      width: auto;
      left: 50%;
      padding: 0 30px;
      height: 40px;
      margin-top: 19px; // Push down by half of the header height
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.-active > div {
      transform: translate(-50%, -50%);
    }
  }

  .rt-table {
    background-color: $white;
  }

  //styles used both in .rt-head & .rt-body
  .rt-thead .rt-th,
  .rt-tbody .rt-td,
  .rt-tfoot .rt-td {
    padding: 8px 10px;
    font-size: 1.3rem;

    // color of fixed border on the RHS
    &.rthfc-td-fixed-right,
    &.rthfc-th-fixed-right {
      border-left-color: $black10;
    }
    &.rthfc-td-fixed-left,
    &.rthfc-th-fixed-left {
      border-right-color: $black10;
    }
  }

  .rt-thead {
    &.-header {
      border-bottom: 1px solid $black10;
      box-shadow: none;
    }
    .rt-tr {
      .rt-th {
        font-weight: 600;
        line-height: 2rem;
        text-align: left;

        &.-sort-desc {
          color: $black100;
        }

        // Fix issue w/ resizer being on top of the Settings column.
        // .rt-resizer z-index is 10.
        &.rthfc-th-fixed {
          z-index: 11;
        }
      }
    }
  }

  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group {
      .needsReview {
        background: #fef9ef;
      }
      .rt-td {
        background: inherit;
        font-weight: 400;
        &:not(.status) {
          //effect all columns except status (statuslabel)
          line-height: 2rem;
        }
      }

      .title {
        .deal-name {
          display: inline;
        }
      }
    }
  }

  .rt-tfoot {
    border-top: 1px solid $black10;
    box-shadow: none;
  }
}
