.modal-backdrop {
  // background: $outlaw-dark;
  background: $outlaw-dark;
  &.in {
    opacity: 0.6;
  }
}

.modal-dialog {
  margin-top: 0;
  padding: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include mediaquery-medium {
    max-width: 540px; //default of siggy
  }

  &.pdf-preview {
    width: $legal-paper-width;
    height: 100%;
    margin-bottom: 0px;
    max-width: 816px !important;

    .outer-paper-layout {
      @include mediaquery-medium {
        max-width: inherit;
      }
    }

    .modal-content {
      height: inherit;
      width: inherit;
      .modal-body {
        height: inherit;
        width: inherit;
        object {
          height: inherit;
          width: inherit;
        }
      }
    }
  }

  //custom things for each modal
  &.export-contract {
    .#{$dmp-checkbox} {
      margin-top: 6px;
    }
    .dmp-loader {
      position: absolute;
    }
    .export-options {
      flex: 1;
      .option:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &.extracted-data-modal {
    @include mediaquery-medium {
      max-width: 800px;
    }

    .modal-content {
      .modal-body {
        width: 760px;
        margin: 20px;

        .dmp-loader {
          display: flex;
          justify-content: center;
        }

        .header-block {
          width: 100%;
          display: flex;
          margin-bottom: 10px;

          .title {
            font-size: larger;
            font-weight: bold;
            align-self: center;
          }
          .action-buttons {
            margin-left: auto;
            .dmp-loader {
              display: flex;
              align-items: center;
            }
          }
        }

        .response-block {
          .pair {
            margin-bottom: 5px;
            .key {
              text-decoration: underline;
              font-weight: bold;
            }

            .value {
              font-size: small;
            }
          }
        }
      }
    }
  }

  &.login-modal,
  &.delete-deal,
  &.export-deal,
  &.lens-info-modal,
  &.delete-integration,
  &.rename-deal,
  &.disclaimer,
  &.new-deal-modal,
  &.deal-style-editor {
    @include mediaquery-medium {
      max-width: 540px;
    }
  }
  &.deal-share,
  &.version-history,
  &.video-player,
  &.docx-uploader,
  &.automator,
  &.batch.static,
  &.timeline-prompt-editor,
  &.data-source-browser.wide {
    @include mediaquery-medium {
      max-width: 920px;
    }
    iframe {
      //fix for on mobile
      min-height: calc(100vh - 60px);
      @include mediaquery-large {
        min-height: 660px;
      }
    }
  }

  &.timeline-prompt-editor {
    .txt-prompt {
      min-height: 300px;
    }
  }

  &.modal-report-config {
    @include mediaquery-medium {
      max-width: 600px;
    }
  }

  // @Keven - I propose this as our standard pattern for Modals that have a loader (which is most of them)
  &.delete-deal,
  &.delete-integration {
    .modal-footer {
      display: flex;
      align-items: center;
      .spacer {
        flex: 1;
      }
    }

    .control-label {
      padding-top: 0;
      color: $gray-1;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .contents {
      .title {
        color: $gray-0;
        text-decoration: underline;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
      }
      .description {
        color: $gray-2;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      .hits {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  &.delete-deal-version {
    .deal-version-block {
      margin-top: 10px;
      padding-bottom: 20px;
    }
  }

  &.dialog-centered {
    height: 100%;
    margin-bottom: 0;

    .modal-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.template-info {
    .modal-body {
      .template-linked-clauses {
        margin-top: -10px;
        margin-bottom: 10px;
      }
    }
  }

  .headline {
    @extend .txt_headline_2;
    margin: 30px 0 20px 0;
  }

  // Moved here because a TeamSelector is used in both NewDeal and ContractUploader modals
  .team-selector-wrapper {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid $black10;
  }

  .team-selector {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .team-filter {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      margin: 0 !important;
    }
  }

  &.export-deal {
    .form-group {
      margin-top: -15px;

      .export-options {
        margin-top: 10px;
      }
    }

    .export-parties-properties {
      margin-top: 5px;
    }

    .address-description {
      display: block;
      margin-left: 30px;
      margin-bottom: 5px;
    }

    .dmp-switch {
      margin-bottom: 5px;
    }

    .address {
      margin-bottom: 0px;
    }

    .omit-null {
      margin-top: 4px;
    }
  }

  &.lens-info-modal {
    .show-zero {
      .dmp-switch {
        margin-left: auto;
      }
      padding: 10px 20px;
      display: flex;
      border-bottom: 1px solid $black10;
    }

    .lens-info-body {
      margin-top: 10px;
      width: inherit;
    }

    .lens-info-error {
      margin: 20px;
    }
  }

  .modal-content {
    //strip colors
    box-shadow: none;
    border: none;
    background: none;
    background: $white;
    border-radius: 0;
    border-bottom-left-radius: $omnisearch-bar-border-radius;
    border-bottom-right-radius: $omnisearch-bar-border-radius;

    .modal-header {
      border-bottom: 1px solid $black10;
      text-align: left;
      position: relative;
      z-index: 2;
      padding: 0;
      .headline {
        color: $black60;
        @extend .font_size_m;
        font-weight: 400;
        margin: 20px;
      }
      .theme-title {
        border-bottom: 1px solid $black10;
      }

      .panel-tabs {
        border-bottom: none;
      }

      //old buttons using bootstrap closeButton prop
      .close {
        z-index: 10; //sit above <input> in search-bar
        color: $black20;
        font-size: 3rem;
        opacity: 1;
        font-weight: 300;
        position: absolute;
        right: 20px;
        top: 14px;
        &:hover,
        &:hover,
        &:focus {
          outline: 0;
          color: $black40;
        }
      }
      img.close {
        //used on the google login
        top: 20px;
      }
    }

    .modal-body {
      z-index: 1;
      padding: 0;
      margin: 0;

      .tab-selector {
        width: 100%;
        display: flex;
        flex-direction: row;
        button {
          flex: 1;
        }
      }

      .wrapper {
        padding: 20px 20px 0 20px;
        .form-group {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
        }

        .fields {
          padding-top: 15px;

          .toggle-only {
            .#{$dmp-checkbox} {
              display: inline-block;
            }
            .contents small {
              padding-left: 22px;
            }
            //The content still have checkboxes, only need to change
            //switches content to be aligned.
            .contents small.content-switch {
              padding-left: 32px;
            }
          }
        }

        .request-container-subtext {
          height: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1b232e;
          margin-top: 10px;

          .request-estimated-time {
            color: #f6b36b;
          }
        }

        .request-container {
          display: flex;
          border: 1px solid #edeff0;
          padding: 15px;
          background-color: #fafafa;

          .request-left {
            flex: 2;
            .request-deal-title {
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              color: #1b232e;
              height: 20px;
            }
            .request-deal-service-provider {
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #8c98a5;
              height: 16px;
            }
          }
          .request-right {
            display: inherit;
            align-items: center;
            justify-content: center;
            .vine-icon {
              path {
                fill: #f1b000;
              }
            }
          }
        }
      }

      p {
        color: $black60;
        @include font-sizing(medium);
        &.bysigning {
          @include font-sizing(small);
        }
      }

      .two-col {
        display: flex;
        align-items: stretch;
        .col {
          flex: 1;
          overflow-y: auto;
          overflow-x: initial;
          max-height: 300px;
          padding-bottom: 10px;

          &:first-child {
            border-right: 1px solid $black10;
            padding-right: 12px;
            padding-top: 20px;
            margin: 0 0px 0 15px;
          }
          &:last-child {
            padding-top: 20px;
            padding-left: 15px;
            .reset {
              margin-right: 7px;
            }
            .dmp-tag {
              margin-right: 25px;
            }
          }
        }
      }
    }

    .modal-footer {
      // border-top: 1px solid $black70;
      // padding: 24px 0;
      border: none;
      padding: 0;
      margin: 20px;
      padding-bottom: 20px;

      .dmp-loader {
        position: absolute;
        left: 20px;
        bottom: 24px;

        &.request-loader {
          left: 500px;
          bottom: 20px;
        }
      }

      .request-error {
        font-size: 1.3rem;
        font-weight: 400;
        color: $alert;
        position: absolute;
        left: 20px;
        bottom: 24px;
      }
    }
  }

  // search modal needs to be at the bottom of .modal-dialogue because it the biggest hack of the modal
  &.search-modal {
    transform: translate(0, 0%) !important; //reset bootstrap animation
    //autosuggest stuff is inside of _app-autosuggest.scss
    //push search bar down on desktop
    @include mediaquery-medium {
      // margin-top: $top-bar-height + 35px;
      margin-top: calc((#{$top-bar-height} - #{$search-bar-height-desktop}) / 2);
      //div is centered by default, offset the left hand side nav
      // margin-left: calc(100% / 2 - 270px + 80px  );
      min-width: 630px;
    }
    .modal-content {
      border-radius: 0; //mobile
      @include mediaquery-medium {
        border-radius: $omnisearch-bar-border-radius; //overall border radius
        //on desktop position X correctly, overrriding CSS .close below
        .modal-header {
          border: 0 !important; //reset default
          .close {
            right: 15px; //override CSS below
            top: 8px;
          }
        }
      }
      .modal-body {
        display: none;
      } //there's no modal-body in search
    }
  }

  //modal to toggle between Draft and Flow environments
  &.draft-flow-switcher {
    //mimic p text
    .environment-info,
    .select-status {
      color: $black70;
      font-size: 1.4rem;
    }
    .select-status {
      padding: 0 0 20px 0;
      border-bottom: 1px solid $black10;
      display: inline-block;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.copy-machine {
    .contents {
      .#{$dmp-checkbox} {
        margin-top: 7px;
      }
      small:not(:last-child) {
        margin-bottom: 13px;
      }
    }
  }

  &.deal-info {
    .control-label {
      margin-top: -5px;
    }
    .contents {
      margin-top: 2px;
    }
  }

  &.filter-manager {
    .modal-body {
      .filter-group-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $black3;
        border-bottom: 1px solid $black10;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 15px 20px;
      }

      .filter-group-list {
        .empty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100px;
          color: $black40;
          font-size: 1.2rem;
        }
      }
    }
    .filter {
      padding: 15px 20px;
      border-bottom: 1px solid $black10;
      position: relative;

      .filter-title {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 2px;
      }
      .filter-filters {
        color: $black40;
        padding-right: 40px;
        // We use <FacetBar> to visualize filters on each search but they are not interactive
        // So override a few styles
        .tag {
          padding-right: 4px;
          cursor: default;
          &::before {
            display: none;
          }
          pointer-events: none;
        }
      }

      &.renaming {
        .actions {
          margin-top: 10px;
          text-align: right;
          :first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.save-filter {
    .radio label {
      display: block;
      line-height: 2em;
    }
  }

  &.column-settings,
  &.table-column-manager {
    .modal-body {
      .col.available {
        .cross-template-switch {
          margin-bottom: 20px;
          font-size: 12px;
          margin-left: 1px;
        }
        .react-autosuggest__container {
          margin-bottom: 10px;
          .react-autosuggest__input {
            margin-bottom: none;
            background-image: none;
            padding: 5px;
            border: 1px solid #d9dee4;
            height: 30px;
            width: 100%;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          }
        }

        .react-autosuggest__suggestions-container {
          border-radius: 5px;
          border: 1px solid $gray-3;

          .react-autosuggest__suggestions-list {
            max-height: 200px;
            overflow-y: scroll;
            overflow-x: hidden;
          }

          .react-autosuggest__suggestion {
            border-top: none;
          }

          ul {
            padding: 5px 0px;
            li {
              .template-var-suggestion {
                padding: 7.5px 12px;
                border-top: none;
                .name {
                  color: #8c98a5;
                }

                .name,
                .header {
                  width: 100%;
                  overflow-wrap: break-word;
                }
              }
            }
          }
        }

        .empty {
          font-size: 1.3rem;
          color: $black30;
          margin-bottom: 20px;
        }

        .form-group {
          display: block;
        }

        ul.cols {
          li {
            margin-bottom: 10px;
          }
          .dmp-tag {
            margin-right: 0;
          }

          &.template-cols {
            margin-bottom: 15px;
          }
        }

        .col.current {
          ul {
            margin-left: 20px;
          }
        }
      }

      .list-title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 700;
        color: $black80;
        display: flex;
        margin-bottom: 10px;
        span:first-child {
          flex: 1;
        }
        button.reset {
          padding: 0;
        }

        .dmp-btn {
          margin-left: auto;
        }
      }

      .active-field {
        font-weight: normal;
        text-decoration: underline;
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      .spacer {
        flex: 1;
      }
      .dmp-loader {
        position: inherit;
      }
      border-top: 1px solid $black10;
      padding: 20px;
      margin: 0;
    }
  }

  &.modal-report-config {
    .modal-body {
      .two-col {
        height: 400px;
        .col {
          max-height: initial;
        }

        .form-group {
          display: block;

          .control-label,
          p {
            font-size: 1.2rem;
          }
        }
      }
    }

    .modal-footer {
      border-top: 1px solid $black10;
      padding: 20px;
      margin: 0;
    }
  }

  &.deal-user-modal-view {
    .address-input {
      display: block;
      width: 100%;
      flex: 1;
    }
    .actions {
      &.me-action {
        display: flex;
        justify-content: space-between;
        .dmp-checkbox {
          padding: 10px 0px;
        }
      }
      &:not(.me-action) {
        display: block;
      }
    }
  }

  .message {
    color: $black80;
    @include font-sizing(medium);
  }
  .description {
    color: $black40;
  }

  &.batch-user-manager {
    .summary {
      padding: 15px 20px;
    }
    .user-lists {
      display: flex;
      align-items: stretch;
      height: 300px;
      padding: 0 20px;

      .current-users {
        border-left: 1px solid $black10;
        border-bottom: 1px solid $black10;
        width: 320px;
        display: flex;
        flex-direction: column;
        .col-headers {
          border: 1px solid $black10;
          border-left: 0;
          border-right: 0;
          padding: 5px 10px;
          label {
            margin-bottom: 0;
          }
          .col-role {
            float: right;
            width: 110px;
          }
        }
        .scroll {
          flex: 1;
          overflow-y: auto;
        }
      }
      &.processing {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .teammate-selector .scroll {
      flex: 1;
      overflow-y: auto;
    }

    .modal-footer {
      display: flex;
      align-items: center;
      .dmp-loader {
        position: inherit;
      }
      .spacer {
        flex: 1;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }
  &.automator,
  &.deal-style-editor {
    // Make this standard???
    .modal-body {
      max-height: calc(100vh - 180px);
      overflow: scroll;
    }

    .field-mappings {
      width: 100%;
      .headings {
        display: flex;
        font-size: 1.3rem;
        font-weight: 700;
        border-bottom: 1px solid $black10;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }

      .headings,
      .field-mapper {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid $black10;
        margin-bottom: 10px;

        .outlaw {
          flex: 4;
          padding-right: 10px;
        }
        .service {
          flex: 4;
          padding-right: 10px;
        }
        .actions {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
  &.deal-style-editor {
    .modal-body {
      min-height: calc(100vh - 130px);
      max-height: calc(100vh - 130px);
      display: flex;
      flex-direction: column;
      overflow: hidden;

      @include mediaquery-medium {
        min-height: 400px;
        max-height: 400px;
        flex-direction: row;
      }

      .page-styles {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        flex: 1;

        .borders,
        .margins,
        .line-numbers {
          margin: 10px 20px;
        }

        .margins {
          max-height: 100px;
        }

        .page-style-container {
          display: inherit;
          flex: row;
          flex: 1;

          .page-style-title {
            font-weight: bold;
            width: 120px;

            .description {
              font-weight: normal;
              font-size: 12px;
            }
          }
          .page-style-body {
            margin-left: 20px;
            display: inherit;
            flex: inherit;
            .form-group {
              display: flex;
              flex-direction: column;
              &:not(:last-child) {
                margin-right: 10px;
              }
              .control-label {
                color: $black60;
                flex-basis: auto;
                padding-top: 0px;
                font-size: 1.2rem;
              }
            }
          }
          .enabled {
            flex-direction: column;
            .border-editor {
              display: flex;
              flex-direction: row;
            }
          }

          .page-editor-dd {
            .dmp-dd-block {
              flex: none;
            }
            ul.dropdown-menu {
              max-height: 200px;
              min-width: 90px !important;
            }
          }
        }
      }

      .style-list {
        min-height: 150px;
        max-height: 150px;
        @include mediaquery-medium {
          width: 185px;
          min-height: inherit;
          max-height: inherit;
        }

        padding: 10px 15px 0;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;

        ul {
          flex: 1;
          li {
            margin-bottom: 10px;
            cursor: pointer;
            text-decoration: none;
            font-weight: 400;
            &:hover {
              text-decoration: underline;
              font-weight: 400;
            }
            &.active {
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
      }
      .ds-editor {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $black10;

        &.advanced {
          .react-json-view {
            padding: 15px;
            max-height: 265px;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }

        &.simple {
          .dmp-form {
            padding: 5px 15px 0;
            border-top: 1px solid $black10;
            min-height: 265px;
            @include mediaquery-medium {
              border-top: none;
            }

            .control-row {
              display: flex;
              width: 100%;
              &:not(:last-child) {
                margin-bottom: 5px;
              }

              .form-group {
                display: flex;
                flex-direction: column;
                &:not(:last-child) {
                  margin-right: 10px;
                }

                &.alignment {
                  width: 156px;
                }
                &.formatting {
                  width: 117px;
                }

                &.size,
                &.line-spacing,
                &.color {
                  flex: 1;
                  .color-picker {
                    min-height: 39px;
                  }
                }

                .control-label {
                  color: $black60;
                  flex-basis: auto;
                }

                ul.dropdown-menu {
                  max-height: 200px;
                }

                .color-picker {
                  z-index: 1;
                }
              }
            }
          }
        }

        .ds-preview {
          padding: 10px 15px;
          max-height: 135px;
          border-top: 1px solid $black10;
          flex: 1;
          overflow-y: scroll;
          overflow-x: hidden;
        }
        .line-numbering {
          p {
            margin: 0px !important;
          }
        }
      }
    }
    .modal-footer {
      border-top: 1px solid $black10;
      padding: 10px 20px;
      margin: 0;
      display: flex;
      align-items: center;
      .spacer {
        flex: 1;
      }
      .dmp-checkbox {
        flex: 1;
        text-align: left;
      }
    }
  }

  &.data-source-browser {
    .modal-content {
      @extend .papershadow;
    }
    .loader {
      display: flex;
      align-items: center;
      .dmp-loader {
        margin: 0 10px 0 0;
      }
    }
    .dmp-data-table {
      border: 1px solid $black10;

      .rt-tr {
        cursor: pointer;
      }
      .rt-td {
        white-space: normal;
        .radio {
          margin-top: 0;
        }
      }
      .rt-tbody {
        max-height: 320px;
      }
    }
    .selected-item {
      margin-top: 10px;
      position: relative;

      .link-clear {
        @extend .clearLink;
      }
    }
    .data-error {
      a {
        text-decoration: underline;
      }
    }
  }
}

// This is for the draggable column li's in <ColumnSettings>.
// It needs to be top level because react-sortable-hoc creates a copy at root of body
// but we can still target with our added "draggable" attribute
li.sortable {
  list-style: none;
  cursor: grab;
  margin-bottom: 10px;

  // Necessary because otherwise the added copy (which we see during drag) is invisible
  z-index: 10000;

  .#{$dmp-drag-handle} {
    margin-left: -10px;
    margin-top: -1px; //dmp-tag is 30px height, border:1px is pushing drag handle down
  }

  .text {
    flex: 1;
  }
}

.split-address-alert {
  margin-top: 10px;
  a {
    text-decoration: none;
    margin-left: 4px;
  }
}

div[role='dialog'] + div[role='dialog'] {
  .modal-backdrop {
    z-index: 9999;
  }
  .modal {
    z-index: 9999;
  }
}
