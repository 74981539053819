/*
  DMP COMPONENT: Icon
*/

$dmp-icon: '#{$dmp}-i';

.#{$dmp-icon} {
  width: 15px;
  height: 15px;
  fill: $gray-0;

  &:not(.no-fill) {
    path,
    g {
      fill: inherit;
    }
  }

  /*
    Dark version
  */
  &-dark {
    fill: $gray-2;
  }

  /*
    Faded version
  */
  &-faded {
    fill: $gray-2;
  }

  /*
    Sizes
  */
  &-small {
    width: 10px;
    height: 10px;
  }

  &-large {
    width: 20px;
    height: 20px;
  }

  &-xlarge {
    width: 40px;
    height: 40px;
  }

  /*
    Colors
    We must use !important here so that it's not being overwritten by button icon overwriting
  */
  &-color {
    &-gray {
      fill: $gray-2 !important;
    }
    &-blue {
      fill: $color-blue !important;
    }
    &-yellow {
      fill: $color-yellow !important;
    }
    &-red {
      fill: $color-red !important;
    }
    &-green {
      fill: $color-green !important;
    }
    &-purple {
      fill: $color-purple !important;
    }
  }

  &[data-icon='syncDisabled'],
  &[data-icon='syncEnabled'] {
    fill: #fff;
  }
}
